/* import __COLOCATED_TEMPLATE__ from './subscription-ending-warning-banner.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import type IntlService from 'ember-intl/services/intl';
import type SubscriptionEndingService from 'embercom/services/subscription-ending-service';
import { tracked } from '@glimmer/tracking';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import { post } from 'embercom/lib/ajax';
interface Args {
  appWide: boolean;
}

interface Signature {
  Element: any;
  Args: Args;
}

export default class SubscriptionEndingWarningBanner extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare purchaseAnalyticsService: $TSFixMe;
  @service declare subscriptionEndingService: SubscriptionEndingService;
  @service declare router: RouterService;
  @service declare modalService: $TSFixMe;
  @service declare store: Store;
  @service declare intercomEventService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;

  @tracked showModal = false;
  @tracked isProcessing = false;

  @action sendAnalyticsEvent({
    action,
    object,
    context,
  }: {
    action: string;
    object: string;
    context?: Record<string, any>;
  }) {
    this.purchaseAnalyticsService.trackEvent({
      action,
      object,
      context,
      place: 'billing-summary-subscription-expiry-warning-banner',
    });
  }

  @action
  openMessenger() {
    showNewMessageInIntercomWidget();
    this.sendAnalyticsEvent({
      action: 'clicked',
      object: 'resume_subscription_talk_to_sales_button',
    });
  }

  @action
  restartSubscription() {
    this.showModal = false;
    this.router.transitionTo('apps.app.billing.manage-subscription');
  }

  @action
  setShowModalTrue() {
    this.showModal = true;
  }

  @action
  async resume() {
    this.isProcessing = true;
    let customer = await post(
      `/ember/customers/${this.subscriptionEndingService.customerId}/resume_pending_cancellation`,
      {
        app_id: this.subscriptionEndingService.appId,
      },
    );
    this.subscriptionEndingService.updateCustomerSubscriptionWillBeCancelledAt(
      customer.subscriptionWillBeCancelledAt,
    );
    this.notificationsService.notifyConfirmation(
      this.intl.t(
        'pricing-and-packaging.subscription_ending_warning_banner.modal.confirmation_message',
      ),
    );
    this.isProcessing = false;
    this.showModal = false;
    this.intercomEventService.trackEvent('resumed-pending-subscription-cancellation');
  }

  get isSalesforceContracted() {
    return this.subscriptionEndingService.isSalesforceContracted;
  }

  get shouldShowBanner() {
    return this.subscriptionEndingService.shouldShowSubscriptionEndingBanner(this.args.appWide);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Banners::SubscriptionEndingWarningBanner': typeof SubscriptionEndingWarningBanner;
    'banners/subscription-ending-warning-banner': typeof SubscriptionEndingWarningBanner;
  }
}
