/* import __COLOCATED_TEMPLATE__ from './confirmation-modal.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Plan from 'embercom/models/plan';
import type RouterService from '@ember/routing/router-service';
import { dropTask } from 'ember-concurrency-decorators';
import type Store from '@ember-data/store';
import { captureException } from 'embercom/lib/sentry';
import type IntlService from 'ember-intl/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import ajax from 'embercom/lib/ajax';

interface Signature {
  Args: {
    redirectSource: 'exit_survey' | 'cancellation_flow' | null;
  };
}

export default class ConfirmationModal extends Component<Signature> {
  @service appService: any;
  @service intercomEventService: any;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare customerService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  get isOpen() {
    return (
      this.args.redirectSource === 'cancellation_flow' || this.args.redirectSource === 'exit_survey'
    );
  }

  get modalDescriptionKey() {
    if (this.args.redirectSource === 'exit_survey') {
      return 'billing.settings.cancel-confirmation-modal.description.exit-survey';
    }
    return 'billing.settings.cancel-confirmation-modal.description.cancellation-flow';
  }

  get app() {
    return this.appService.app;
  }

  get planName() {
    return this.currentSubscriptionPlan.name;
  }

  get currentSubscriptionPlan() {
    return this.app.allPlansOnPricingModel.find(
      (plan: Plan) => plan.billableCustomerPlan && !plan.product.addon,
    );
  }

  @action
  async handleCancellation() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'continue_to_cancel_subscription',
      section: 'cancel_subscription.confirmation_modal',
      place: 'billing_settings',
    });

    await taskFor(this.cancelSubscriptionTask).perform();
    this.router.transitionTo('apps.app.settings.subscription.billing.settings', {
      queryParams: {
        redirect_source: null,
      },
    });
  }

  @action
  handleModalClose(object: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      section: 'cancel_subscription.confirmation_modal',
      place: 'billing_settings',
      context: 'abort_cancel_subscription',
    });
    this.router.transitionTo('apps.app.settings.subscription.billing.settings', {
      queryParams: {
        redirect_source: null,
      },
    });
  }

  @dropTask *cancelSubscriptionTask(): Generator<Promise<void>> {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'cancel_my_subscription_button',
      place: 'settings.subscription.billing.cancel.confirm',
      section: 'cancel.confirm_screen',
      context: 'billing_settings',
    });
    try {
      let newCustomer = yield ajax({
        url: `/ember/customers/${this.customerService.customer.id}/cancel_subscription`,
        type: 'POST',
        data: JSON.stringify({
          app_id: this.appService.app.id,
        }),
      });
      this.store.pushPayload('billing/customer', {
        'billing/customer': newCustomer,
      });
      this.router.transitionTo('apps.app.settings.subscription.billing.settings', {
        queryParams: {
          redirect_source: null,
        },
      });
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('new-settings.workspace.billing.cancel.confirm.error'),
      );
      captureException(err, {
        'responsible-team': 'team-monetization',
      });
      throw err;
    }
  }

  get isCancelSubscriptionRunning() {
    return taskFor(this.cancelSubscriptionTask).isRunning;
  }

  set isCancelSubscriptionRunning(_value: $TSFixMe) {
    // no-op
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Settings::Cancel::ConfirmationModal': typeof ConfirmationModal;
    'billing/settings/cancel/confirmation-modal': typeof ConfirmationModal;
  }
}
